import React, { useEffect, useContext } from "react";
import { UserContext } from "./auth/userContext";
import Routers from "./routes";
import PermissionChecker from "./auth/RolePermissionChecker";
import LoadingFrame from "./generalComponents/loadingFrame";

import GuestLayout from "./pages/endOfSummer/layout/nav";
export default function ContentLayout(appProps) {
  const userContext = useContext(UserContext);

  useEffect(() => {
    console.log(userContext.pageId);
    userContext.addPageId();
  }, []);
  const contentLayout = (key) => {
    var address = window.location.pathname;
    if (appProps.isLoading) {
      return <LoadingFrame loading={true}></LoadingFrame>;
    }

    if (address == "/") {
      return <Routers {...appProps} />;
    }

    if (address == "/login" || address == "/register") {
      return (
        <GuestLayout>
          <Routers {...appProps} />
        </GuestLayout>
      );
    }

    // if (address.includes("judge")) {
    //   return (
    //     <JudgeLayout {...appProps}>
    //       <Routers {...appProps} />
    //     </JudgeLayout>
    //   );
    // }

    if (address.includes("end-of-summer")) {
      return (
        <GuestLayout {...appProps}>
          <Routers {...appProps} />
        </GuestLayout>
      );
    }
    // if (appProps.isAuthenticated && address == "/404") {
    //   return (
    //     <MemberLayout {...appProps}>
    //       <Routers {...appProps} />
    //     </MemberLayout>
    //   );
    // }

    return <Routers {...appProps} />;
  };
  return <div>{contentLayout(userContext.pageId)}</div>;
}
