import {
  Form,
  Input,
  InputNumber,
  Button,
  Switch,
  Row,
  Col,
  Divider,
  DatePicker,
  message,
  Spin,
  Radio,
  Steps,
  Typography,
} from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";

import SelectTicketType from "./formComponents/selectTicketType";
import Payments from "./formComponents/payments";
import Billing from "./formComponents/billing";
import Confirmation from "./formComponents/confirmation";
import React, { useEffect, useState } from "react";

import Axios from "axios";
import { environmentVariable, endpoints, apiRoutes } from "../../auth/apiRoute";
const { Title } = Typography;
const layout = {
  wrapperCol: {
    span: 24,
  },
};
const { Step } = Steps;
const gutterLayout = { xs: 8, sm: 16, md: 24, lg: 32 };

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const ticketId = "1010000001";

const Checkoutform = (appProps) => {
  console.log(appProps);
  const [ticketAmount, setTicketAmount] = useState(0);
  const [ifWithGuests, setIfWithGuests] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [unitPrice, setUnitPrice] = useState();
  const [ticket, setTicket] = useState({ golf: 500, cocktail: 200 });

  const [currentStep, setCurrentStep] = useState(0);
  const [confirmationMessage, setConfirmationMessage] = useState();
  const [formValues, setFormValues] = useState({});
  const [salesItem, setSalesItem] = useState({});
  const [customerId, setCustomerId] = useState();
  const [gettingValues, setGettingValues] = useState(true);
  const [customerEmail, setCustomerEmail] = useState();
  useEffect(() => {
    getItem();
  }, []);
  const getItem = async () => {
    console.log("getting items:,", ticketId);
    var baseUrl = endpoints.general.ticketPrice;
    try {
      const response = await Axios.post(baseUrl, {
        ticketId: ticketId,
      });

      if (response.status == 200) {
        var newData = response.data;
        console.log(newData);
        setSalesItem(newData);
        setUnitPrice(newData.UnitPrice);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values) => {
    setFormValues({ ...values, ...formValues });

    var message = "Success! You will receive a confirmation shortly.";

    if (currentStep == 0) {
      setCurrentStep(currentStep + 1);
    }
    if (currentStep == 1) {
      await checkAndCreateCustomer(values);
    }
    if (currentStep == 2) {
      if (values.paymentMethod && values.paymentMethod == "check") {
        message = "Success! You will receive a confirmation shortly.";
      }
      setConfirmationMessage(message);
      console.log(formValues);
      var newFormValues = {
        ...formValues,
        ...values,
        ...{ salesItem: salesItem },
        ...{ customerId: customerId },
      };

      newFormValues.salesItem = salesItem;

      console.log(newFormValues);
      submitInfo(newFormValues);
    }
  };

  const checkAndCreateCustomer = async (values) => {
    var baseUrl = endpoints.general.createCustomer;

    setLoadingCustomer(true);
    try {
      const response = await Axios.post(baseUrl, values);
      console.log(response);
      setLoadingCustomer(false);

      if (response.status == 200) {
        setCustomerId(response.data.customerId);
        setCustomerEmail(values.user.email);
        setCurrentStep(currentStep + 1);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        message.error({
          content: "Sorry, there was an error. " + error.response.data.message,
          className: "messageBox",
        });
      }

      setLoadingCustomer(false);
    }
  };
  const submitInfo = async (values) => {
    var baseUrl = endpoints.endOfSummer.charge;

    setLoading(true);
    try {
      const response = await Axios.post(baseUrl, values);
      console.log(response);
      if (response.status == 200) {
        setCurrentStep(currentStep + 1);
        form.resetFields();
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        message.error({
          content: "Sorry, there was an error. " + error.response.data.message,
          className: "messageBox",
        });
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const onSelectTicket = async (amount, type) => {
    setTicketAmount(amount);
  };

  const goPrevious = () => {
    setCurrentStep(currentStep - 1);
  };
  const showFormContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <SelectTicketType
            onChange={(amount, type) => onSelectTicket(amount, type)}
          />
        );
      case 1:
        return (
          <Spin spinning={loadingCustomer}>
            <Billing />
          </Spin>
        );
      case 2:
        return <Payments email={customerEmail} />;
      case 3:
        return <Confirmation confirmationMessage={confirmationMessage} />;
      default:
    }
  };
  const showTotal = () => {
    return (
      <div className="totalAmount">
        <h4>Total: {totalPrice()}</h4>
        <Divider />
        <p>Tickets: {ticketAmount}</p> <p>Price: $ {unitPrice} / Ticket</p>
      </div>
    );
  };
  const totalPrice = () => {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    var total = unitPrice * ticketAmount;

    return formatter.format(total);
  };

  if (appProps.isLocked) {
    return (
      <Title level={4}>
        The event is now closed. We would like to thank all those who bought
        tickets.
      </Title>
    );
  }
  return (
    <Row gutter={gutterLayout}>
      <Title level={2}>End of Summer Celebration </Title>

      <>
        <Steps
          current={currentStep}
          // onChange={(value) => setCurrentStep(value)}
        >
          <Step title="Ticket" icon={<UserOutlined />} />
          <Step title="Billing" icon={<SolutionOutlined />} />
          <Step
            title="Sending Invoice"
            icon={loading ? <LoadingOutlined /> : <CreditCardOutlined />}
          />
          <Step title="Confirmation" icon={<SmileOutlined />} />
        </Steps>
        <Col span={currentStep == 3 ? 24 : 18} style={{ marginTop: "2rem" }}>
          <Form
            form={form}
            {...layout}
            name="nest-messages"
            layout="vertical"
            onFinish={onFinish}
            validateMessages={validateMessages}
            initialValues={{
              guests: [{ first: "", last: "" }],

              paymentMethod: "check",
            }}
          >
            {showFormContent()}

            {currentStep == 3 ? (
              <></>
            ) : (
              <Form.Item>
                <Row gutter={gutterLayout}>
                  {" "}
                  {currentStep == 0 ? (
                    <></>
                  ) : (
                    <Col span={12}>
                      <Button
                        type="primary"
                        disabled={loading ? true : false}
                        onClick={goPrevious}
                        block
                      >
                        Previous
                      </Button>
                    </Col>
                  )}
                  <Col span={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={loading ? true : false}
                      block
                    >
                      {currentStep == 2 ? "Confirm" : "Next"}
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            )}
          </Form>
        </Col>
        {currentStep == 3 ? (
          <></>
        ) : (
          <Col span={6} style={{ marginTop: "2rem" }}>
            {showTotal()}
          </Col>
        )}
      </>
    </Row>
  );
};

export default Checkoutform;
