import React, { useEffect, useState } from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import { apiRoutes, environment } from "./apiRoute";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Axios from "axios";
import {
  Form,
  Input,
  Button,
  Radio,
  Upload,
  Spin,
  Result,
  message,
  Typography,
  Alert,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;

function Register() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [isLoading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [isAuthed, setAuth] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setMsg] = useState("");

  const register = async (values) => {
    const { email, password } = values;
    Axios.post(apiRoutes[environment].register, {
      email: email,
      password: password,
    })
      .then((resp) => {
        setErr(false);
        console.log(resp.data);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
        setAuth(true);
        return resp.data;
      })
      .catch((err) => {
        console.log(err.response.data);
        setErr(true);
        setMsg(err.response.data);
      });
  };
  useEffect(() => {}, [user, isLoading]);

  const onFinish = async (values) => {
    console.log("Success:", values);

    setLoading(true);

    const { email, password } = values;
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log(user);
      setErr(false);
      const resp = await Axios.post(apiRoutes[environment].register, {
        idToken: user.accessToken,
      });
      const userProfile = resp.data;
      setTimeout(() => {
        navigate(`/member/${userProfile._id}/profile`);
        navigate(0);
      }, 3000);
      setAuth(true);
    } catch (error) {
      console.log(error.message);
      const errorCode = error.code;
      var errorMessage = error.message;
      if (error.code == "auth/email-already-in-use") {
        errorMessage = "The email address is already in use";
      } else if (error.code == "auth/invalid-email") {
        errorMessage = "The email address is not valid.";
      } else if (error.code == "auth/operation-not-allowed") {
        errorMessage = "Operation not allowed.";
      } else if (error.code == "auth/weak-password") {
        errorMessage = "The password is too weak.";
      }
      setErr(true);
      setMsg(errorMessage);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div
      // className="card"
      style={{
        width: "450px",
        maxWidth: "500px",
        margin: "auto",
        background: "white",
        padding: "2rem",
      }}
    >
      {isAuthed ? (
        <div style={{ marginBottom: "2rem" }}>
          <Alert
            message="Success!"
            description={`Please login...`}
            type="success"
            closable
          />
        </div>
      ) : (
        <></>
      )}

      {err ? (
        <>
          <div style={{ marginBottom: "2rem" }}>
            <Alert
              message="Registration went wrong:"
              description={`${errMsg}`}
              type="error"
              closable
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <Spin spinning={isLoading}>
        <Form
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 17,
          }}
          onFinish={onFinish}
          initialValues={{}}
          scrollToFirstError
        >
          {/* <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input style={{ maxWidth: "400px" }} />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input style={{ maxWidth: "400px" }} />
          </Form.Item> */}
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              style={{ maxWidth: "400px" }}
              onChange={(e) => {
                // setEmail(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Confirm Email"
            name="email2"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please confirm your email!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("email") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two email that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input
              style={{ maxWidth: "400px" }}
              onChange={(e) => {
                // setEmail(e.target.value);
              }}
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password style={{ maxWidth: "400px" }} />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 7,
              span: 17,
            }}
            style={{ marginTop: "25px" }}
          >
            <Button type="primary" htmlType="submit">
              Register
            </Button>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 7,
              span: 17,
            }}
          >
            Already have an account? <Link to="/login">Login</Link> now.
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}
export default Register;
