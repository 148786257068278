import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

export default function NotFound(props) {
  return (
    <Result
      key={props.user}
      style={{ marginTop: "35px" }}
      status="404"
      title="Page not found. 404"
      extra={
        <Button type="primary" key="console">
          <Link to="/">Home</Link>
        </Button>
      }
    />
  );
}
