import React, { createContext, useState, useEffect } from "react";
import Axios from "axios";
import { apiRoutes, baseUrl } from "./apiRoute";

const UserContext = createContext();

const UserContextProvider = (appProps) => {
  // the value that will be given to the context
  const [profileNeeded, setProfileComplete] = useState(true);
  const [pageId, setPageId] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [jobs, setJobs] = useState({});
  const [settingData, setSettingData] = useState();

  const [roles, setRoles] = useState();
  const [view, setView] = useState("candidate");
  const [isEmployer, setEmployer] = useState(false);
  const [user, setUser] = useState();
  const [hotels, setHotels] = useState();
  const [loadingNewPage, setLoadingNewPage] = useState(false);

  // let mounted = false;
  // useEffect(() => {
  //   if (!mounted) {
  //     getHotels();
  //     checkRole();
  //   }

  //   return () => {
  //     mounted = true;
  //   };
  // }, []);

  useEffect(() => {
    addPageId();
  }, [loadingNewPage]);

  const addPageId = () => {
    setPageId(pageId + 1);
  };
  const getHotels = async () => {};
  const checkRole = async () => {};

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider
      value={{
        hotels,
        user,
        setUser,
        pageId,
        addPageId,
        settingData,
        isLoading,
        setLoading,

        view,
        setView,
        isEmployer,

        profileNeeded,
        jobs,

        loadingNewPage,
        setLoadingNewPage,
      }}
    >
      {appProps.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
