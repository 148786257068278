import React, { useState } from "react";
import { Form, Spin, Radio, Alert, Typography, Divider } from "antd";
import PayByCard from "./payByCard";
export default function Payments(props) {
  console.log();
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [loading, setLoading] = useState(false);
  return (
    <div>
      {" "}
      <Spin spinning={loading}>
        <Alert
          message={`Invoice will be sent to your email address : ${props.email}.`}
          description={
            <Typography.Paragraph>
              Once you confirm the purchase, you will receive an invoice from
              quickbooks@notification.intuit.com. Please check your spam folder
              if you do not see it in your inbox.
            </Typography.Paragraph>
          }
          type="info"
          showIcon
        />
        <Divider />
        {/* <Form.Item
          name={"paymentMethod"}
          label="Pay by check or card?"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group
            onChange={(e) => {
              setPaymentMethod(e.target.value);
            }}
          >
            <Radio.Button value="check">Check</Radio.Button>
            <Radio.Button value="card">Card</Radio.Button>
          </Radio.Group>
        </Form.Item> */}
        {/* {paymentMethod == "card" ? <PayByCard loading={loading} /> : <></>} */}
      </Spin>
    </div>
  );
}
