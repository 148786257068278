import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";

import { Layout, Spin, Card, Avatar, Skeleton } from "antd";

import { environment } from "./auth/apiRoute";
import PermissionChecker from "./auth/RolePermissionChecker";
import PrivateRoute from "./auth/privateRoute";
import NotFound from "./generalComponents/notFound";
import LoginPage from "./auth/login";
import RegisterPage from "./auth/register";

import PasswordResetRequest from "./auth/passwordResetRequest";
import ResetRequest from "./auth/resetPassword";

import Checkoutform from "./pages/endOfSummer/form";
import Addguests from "./pages/endOfSummer/addGuests";
import SearchInvoice from "./pages/endOfSummer/searchInvoice";

export default function Routers(appProps) {
  const navigate = useNavigate();

  const ProtectedRoute = ({
    isLoading,
    user,
    isAuthenticated,
    redirectPath = "/login",
  }) => {
    // console.log(pathname);
    if (isLoading) {
      return <Skeleton active />;
    }

    if (!isAuthenticated) {
      return <Navigate to={redirectPath} replace />;
    }
    // if (!user.id && pathname != "/member/new/profile") {
    //   console.log("redirect to profile");
    //   return <Navigate to={"/member/new/profile"} replace />;
    // }
    return <Outlet />;
  };
  const AdminOnly = ({
    isLoading,
    isAuthenticated,
    user,
    redirectPath = "/",
  }) => {
    if (isLoading) {
      return <Skeleton active />;
    }

    if (isAuthenticated && user.roles.includes("admin")) {
      return <Outlet />;
    }

    return <Navigate to={redirectPath} replace />;
  };

  const RedirectIfLogin = ({
    isLoading,
    isAuthenticated,
    redirectPath = "/",
  }) => {
    return <Outlet />;
    // if (isLoading) {
    //   return <Skeleton active />;
    // }
    // if (!isAuthenticated) {
    //   return <Outlet />;
    // }

    // return <Navigate to={redirectPath} replace />;
  };
  const LogoutPage = ({ isLoading, logout, redirectPath = "/" }) => {
    logout();
    navigate(0);
    return <Navigate to={redirectPath} replace />;
  };

  const guestRoutes = [
    { path: "/", element: <Checkoutform {...appProps} /> },
    {
      path: "/end-of-summer",
      element: <Checkoutform {...appProps} {...{ isLocked: false }} />,
    },
  ];
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/404" replace />}></Route>
      <Route exact path="/404" element={<NotFound {...appProps} />} />
      <Route
        exact
        path="/reset-password"
        element={<PasswordResetRequest />}
      ></Route>
      <Route
        exact
        path="/set-password/:token"
        element={<ResetRequest />}
      ></Route>

      <Route
        exact
        path="/logout"
        element={<LogoutPage {...appProps} />}
      ></Route>
      <Route>
        <Route element={<RedirectIfLogin {...appProps} />}>
          <Route
            exact
            path="/login"
            element={<LoginPage {...appProps} />}
          ></Route>
          <Route
            exact
            path="/register"
            element={<RegisterPage {...appProps} />}
          ></Route>
        </Route>
      </Route>

      {guestRoutes.map((item) => {
        return (
          <Route
            exact
            key={item.path}
            path={item.path}
            element={item.element}
          ></Route>
        );
      })}
    </Routes>
  );
}
