export const environment = "live";

const Production_URL = "";
const Sandbox_URL = "http://localhost:8113";

export const environmentVariable = {
  apiURL: environment == "dev" ? Sandbox_URL : Production_URL,
};
export const endpoints = {
  general: {
    ticketPrice: environmentVariable.apiURL + "/ticket-price",
    createCustomer: environmentVariable.apiURL + "/create-customer",
  },
  endOfSummer: {
    charge: environmentVariable.apiURL + "/eos/charge",
  },
};
export const apiRoutes = {
  dev: {
    auth: "http://localhost:8991/checkauth",
    verifyToken: "http://localhost:8991/verifyToken",
    login: "http://localhost:8991/login",
    logout: "http://localhost:8991/logout",
    register: "http://localhost:8991/register",
    hotels: "http://localhost:8991/hotels",
    updateprofile: "http://localhost:8991/updateprofile",
    getprofile: "http://localhost:8991/getprofile",
    hotelAdmin: "http://localhost:8991/hotelAdmin",
    updateNomination: "http://localhost:8991/nomination/update",
    hashNomination: "http://localhost:8991/nomination/hash",
    nominations: "http://localhost:8991/nominations",
    mynominations: "http://localhost:8991/my/nominations",
    getnomination: "http://localhost:8991/api/get/nomination",
    deleteNomination: "http://localhost:8991/api/delete/nomination",
    passwordResetRequest: "http://localhost:8991/user/password-reset-request",
    passwordReset: "http://localhost:8991/user/password-reset",
    getHotel: "http://localhost:8991/hotels/view",
    searchUser: "http://localhost:8991/api/users/search",

    allmembers: "http://localhost:8991/api/users/all",
    alljudges: "http://localhost:8991/api/users/judges",
    updateStaff: "http://localhost:8991/hotels/staff/update",
    removeStaff: "http://localhost:8991/hotels/staff/remove",
    addNominationsToJudge: "http://localhost:8991/api/nomination/judge/add",
    mynominationstojudge: "http://localhost:8991/api/judge/nominations",
    updateScore: "http://localhost:8991/api/judge/score/update",
    applyforjudge: "http://localhost:8991/api/judge/apply",
    //
    shortList: "http://localhost:8991/api/final/judge/nominations",
    updateFinalScore: "http://localhost:8991/api/judge/final/score/update",
    //
    adminUpdateMemberHotel:
      "http://localhost:8991/api/admin/member/update/hotels",
    adminUpdateMemberRole: "http://localhost:8991/api/user/role/update",
    adminUpdateNomination: "http://localhost:8991/api/admin/nomination/update",
  },

  live: {
    auth: "/checkauth",
    verifyToken: "/verifyToken",
    login: "/login",
    logout: "/logout",
    register: "/register",
    hotels: "/hotels",
    updateprofile: "/updateprofile",
    getprofile: "/getprofile",
    hotelAdmin: "/hotelAdmin",
    updateNomination: "/nomination/update",
    hashNomination: "/nomination/hash",
    mynominations: "/my/nominations",
    nominations: "/nominations",
    getnomination: "/api/get/nomination",
    deleteNomination: "/api/delete/nomination",
    passwordResetRequest: "/user/password-reset-request",
    passwordReset: "/user/password-reset",
    getHotel: "/hotels/view",
    searchUser: "/api/users/search",

    allmembers: "/api/users/all",
    alljudges: "/api/users/judges",
    updateStaff: "/hotels/staff/update",
    removeStaff: "/hotels/staff/remove",
    addNominationsToJudge: "/api/nomination/judge/add",
    mynominationstojudge: "/api/judge/nominations",
    updateScore: "/api/judge/score/update",
    applyforjudge: "/api/judge/apply",
    //final judge
    finalList: "/api/final/judge/nominations",
    updateFinalScore: "/api/judge/final/score/update",
    //admin
    adminUpdateMemberHotel: "/api/admin/member/update/hotels",
    adminUpdateMemberRole: "/api/user/role/update",
    adminUpdateNomination: "/api/admin/nomination/update",
  },
};
