import "./App.css";

import withAuthProvider from "./auth/AuthProvider";

import { BrowserRouter } from "react-router-dom";

import { UserContextProvider } from "./auth/userContext";
import ContentLayout from "./ContentLayout";

function App(appProps) {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <ContentLayout {...appProps} />
      </BrowserRouter>
    </UserContextProvider>
  );
}

export default App;
