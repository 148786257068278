import React, { useState } from "react";
import { InputNumber, Form, Typography } from "antd";
const { Text, Paragraph } = Typography;

export default function SelectTicketType(props) {
  return (
    <div>
      <Text strong>Tickets</Text>

      <Form.Item
        name={"ticketAmount"}
        label={"How many tickets would you like to purchase?"}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber
          onChange={(value) => props.onChange(value, "golf")}
          min={0}
          max={100}
          keyboard={true}
          formatter={(value) => `${value}`.replace(/\./g, "")}
        />
      </Form.Item>
    </div>
  );
}
