import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { apiRoutes, environment } from "./apiRoute";
import { Form, Input, Button, Checkbox, Spin, Alert } from "antd";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import firebaseApp from "./auth";
import Axios from "axios";
import { UserContext } from "./userContext";
Axios.defaults.withCredentials = true;

function Login(props) {
  const auth = getAuth(firebaseApp);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState({});
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [isAuthed, setAuth] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setMsg] = useState("");
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const userContext = useContext(UserContext);
  const login = async (email, password) => {
    return Axios.post(apiRoutes[environment].login, {
      email: email,
      password: password,
    })
      .then((resp) => {
        console.log(resp.data);

        return resp.data;
      })
      .catch((err) => {
        console.log(err.response.data);
        throw err;
      });
  };
  const onFinish = async (values) => {
    setLoading(true);
    const { email, password } = values;

    try {
      var userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log(user);

      setAuth(true);

      const resp = await Axios.post(apiRoutes[environment].login, {
        idToken: user.accessToken,
      });
      const userProfile = resp.data;
      console.log(userProfile);
      user.id = userProfile._id;
      setUser(user);
      if (search.get("next")) {
        navigate(search.get("next"));
        return navigate(0);
      }
      if (userProfile.firstName) {
        setTimeout(() => {
          navigate("/member/nominations");
          navigate(0);
        }, 1500);
      } else {
        setTimeout(() => {
          navigate(`/member/${userProfile._id}/profile`);
          navigate(0);
        }, 1500);
      }
      // props.login(user);
    } catch (error) {
      console.log(error.message);

      const errorCode = error.code;
      var errorMessage = error.message;
      if (error.code == "auth/email-already-in-use") {
        errorMessage = "The email address is already in use";
      } else if (error.code == "auth/invalid-email") {
        errorMessage = "The email address is not valid.";
      } else if (error.code == "auth/operation-not-allowed") {
        errorMessage = "Operation not allowed.";
      } else if (error.code == "auth/user-not-found") {
        errorMessage = "The email address is not found.";
      } else if (error.code == "auth/wrong-password") {
        errorMessage = "The password is wrong.";
      }
      setErr(true);
      setMsg(errorMessage);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    console.log("loading login page");
    userContext.setLoadingNewPage(true);

    if (!loading) {
      // maybe trigger a loading screen
      // userContext.addPageId();
      return;
    }
    if (user) {
      console.log(user);

      // navigate("/jobs");
    }
  }, []);

  return (
    <div>
      <div
        // className="card"
        style={{
          width: "450px",
          maxWidth: "500px",
          margin: "auto",
          background: "white",
          padding: "2rem",
          top: "60%",
        }}
      >
        {isAuthed ? (
          <div style={{ marginBottom: "2rem" }}>
            <Alert
              message="Success!"
              description={``}
              type="success"
              closable
            />
          </div>
        ) : (
          <></>
        )}
        {err ? (
          <>
            <div style={{ marginBottom: "2rem" }}>
              <Alert
                message="Error Login:"
                description={`${errMsg}`}
                type="error"
                closable
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <Spin spinning={loading}>
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <div>
            <Link to="/reset-password">Forgot Password</Link>
          </div>
          <div>
            Don't have an account? <Link to="/register">Register</Link> now.
          </div>
        </Spin>
      </div>
    </div>
  );
}
export default Login;
