// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBtY3wzU8PqqU94FsLTxzJo6uR41bSGHAA",
  authDomain: "vuefirebase-c2006.firebaseapp.com",
  databaseURL: "https://vuefirebase-c2006.firebaseio.com",
  projectId: "vuefirebase-c2006",
  storageBucket: "vuefirebase-c2006.appspot.com",
  messagingSenderId: "515689281849",
  appId: "1:515689281849:web:eefcf1cc53f3864990d0e8",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
